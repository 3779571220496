import React from "react";
import { Link
 } from "react-router-dom";
// import Image from "./Images";
// import img1 from '../images/vintage-typewriter-on-wooden-table-200x250.jpg';

const CardAction = (props) => {

    return (

        <div class="col-md-4 mt-4">
            <div class="card mb-4 box-shadow" style={{ minHeight: '100%'}}>
                
                <img className="card-img-top" src={`${props.item.image}`} alt="Thumbnail [100%x225]" style={{height: '225px', width: '100%',  display: 'block'}} data-holder-rendered="true"/>
                <div class="card-body">
                    <h4 class="my-0 font-weight-normal text-left">{props.item.title}</h4>
                    <p class="card-text">{props.item.description}</p>
                    
                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-between align-items-center">

                        <div class="btn-group">
                            <Link to={props.item.href}>
                                <button type="button" class="btn btn-sm btn-outline-secondary">Acessar</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default CardAction

        /* <div className="col-md-6 col-sm-12">
            <div className="card flex-md-row mb-4 box-shadow h-md-250">
                <div className="card-body d-flex flex-column align-items-start">
                    <h3 className="mb-0">
                        {props.item.title}
                    </h3>
                    <strong className="d-inline-block mb-2 text-success mb-3">{props.item.context}</strong>
                    <p className="card-text mb-auto">{props.item.description}.</p>
                    <a href={props.item.href}>Acessar</a>
                </div>
                <img className="card-img-right flex-auto" src={`${props.item.image}`} alt="Thumbnail [200x250]" style={{ width: '200px', height: '250px' }} data-holder-rendered="true"/>
            </div>
        </div> */
