import React from "react";
import { Link, NavLink } from 'react-router-dom';
import logo from '../images/logo_only.svg';

const Navbar = () => {
    return(
        <nav className="navbar fixed-top navbar-expand-md navbar-dark bg-dark">
            <div className="container-fluid ">

                <Link to="/" className="navbar-brand">
                    <img src={logo} className="me-3" alt="Analise.Digital"/>
                    Análise.Digital
                </Link>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                    <ul className="navbar-nav ">
                        <li className="nav-item">
                            <NavLink className="nav-link text-light" aria-current="page" to="/">
                                Home
                            </NavLink>
                        </li>   
                        <li className="nav-item">
                            <NavLink  className="nav-link text-light" to="/about">
                                Sobre
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link text-light" to="/contact">
                                Contato
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navbar