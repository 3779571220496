import React from "react";
import logo from '../images/logo_only.svg';

const Contact = (props)=>{

    return (

        <div id="content" className="container pt-5 text-dark" style={{minHeight: "71vh"}}>
                <div className="row">
                    <div className="col-md-6 col-sm-12 text-center">
                            <img src={logo} className="thumbnail " alt="Analise.Digital" width="200" height="200"/>
                    </div>
                    <div className="col-md-6 col-sm-12 p-4 ps-5 text-begin">
                        <h3 className="mb-0 py-4">Entre em contato</h3>
                        <a href="mailto: analisedigitalbr55@gmail.com" className="stretched-link">
                            <span className="d-inline-block mb-2 text-primary fs-3">analisedigitalbr55@gmail.com</span>
                        </a>
                    </div>
                </div>
            </div>
            
        )
}

export default Contact;

