import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Navbar from "./components/Navbar";
import Home from "./pages/Home"
import About from "./pages/About"
import Contact from "./pages/Contact"


function App() {

  return (
    <BrowserRouter>
      <Navbar/>
      <Routes>
          <Route path="/" exact element={<Home/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/contact" element={<Contact/>} /> 
          <Route path='/*' element={<Home/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;