import React from "react";
import logo from '../images/logo_only.svg';

const About = (props)=>{

    return (

            <div id="content" className="container pt-5 text-dark" style={{minHeight: "71vh"}}>
                <div className="row">
                    <div className="col-md-6 col-sm-12 text-center">
                            <img src={logo} className="thumbnail " alt="Analise.Digital" width="200" height="200"/>
                    </div>
                    <div className="col-md-6 col-sm-12 p-4 ps-5 text-begin">
                        <h3 className="mb-0 pb-4">Quem somos</h3>
                        <p className="lh-lg">
                            Pessoas com vontade de fazer a difereça.<br/>
                            Big Data é nossa paixão.<br/>
                            <i className="fa fa-database fa-lg" aria-hidden="true"></i>
                            <i className="fa fa-heart fa-lg" aria-hidden="true"></i>
                            <i className="fa fa-microchip fa-lg" aria-hidden="true"></i>
                        </p>
                    </div>
                </div>
            </div>
            
        )
}

export default About;