import React from "react";
import CardAction from "../components/CardAction";
import homeContentItens from "../data/homeContentItens.json";

const Home = (props)=>{

    return (

        <>
            <div id="introduction" className="text-white set_background_image">
                <div className="container text-left py-5 px-3 ">

                    <h1 className="pb-3">
                        Transformando dados em ação!
                    </h1>

                    <p className="lead pb-3">
                        Nosso objetivo é facilitar o acesso aos dados e nosso trabalho está só no início...
                    </p>
                    <p className="text-success">
                        Diariamente são produzidos cerca de 2.2 milhões de terabytes de dados no mundo e o desafio
                        é transformá-los em informação de fácil acesso.
                    </p>
                </div>
            </div>

            <div className="container text-left py-3 px-3 ">
                    <div className="row mb-2">
                    {homeContentItens.map((item, idx) => 
                            <CardAction key={idx} item={item}/>
                        )}
                    </div>

            </div>
             
        </>
            
        )
}

export default Home;